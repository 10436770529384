/* eslint-disable react-hooks/exhaustive-deps */
import { Formik, Field, Form } from 'formik';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import { FormFeedback, FormGroup, Spinner } from 'reactstrap';
import * as yup from 'yup';

import { _translate as t } from '@/services/core';

import CustomInput from '@/components/common/CustomInput';
import useLogin from './hooks/use-login.hook';
import { LoginFormProps } from '@/entities/login-props-interface';
import { useEffect } from 'react';

const LoginFormComponent = ({ onSuccess, onFailed, onClick, primaryColor }: LoginFormProps) => {
  const { loading, onSubmit, togglePassword, passwordType, connectWithToken } = useLogin({ onSuccess, onFailed });

  useEffect(() => {
    connectWithToken();
  }, []);

  return (
    <div className='login-form-contenu m-auto'>
      <p className='fs-title-login col-9 m-auto '>{t('userapp_connect')}</p>
      <p className='fs-text-login col-10 m-auto'>{t('userapp_text_connect')}</p>

      <>
        <Formik
          onSubmit={onSubmit}
          initialValues={{ username: '', password: '' }}
          validationSchema={yup.object({
            username: yup.string().required(t('common_form_field_required')),
            password: yup.string().required(t('common_form_field_required')),
          })}
        >
          {({ handleSubmit, handleBlur, errors, touched }) => (
            <Form noValidate role='form' onSubmit={handleSubmit}>
              <FormGroup className='m-auto col-10'>
                <CustomInput
                  tag={Field}
                  name='username'
                  autoComplete='false'
                  onBlur={handleBlur}
                  invalid={Boolean(errors.username) && touched.username}
                  type='email'
                  placeholder='userapp_email_placeholder'
                  className='input-login mb-1'
                />
                {touched.username && <FormFeedback>{errors.username}</FormFeedback>}
                <CustomInput
                  tag={Field}
                  type={passwordType && 'password'}
                  name='password'
                  autoComplete='false'
                  icon={passwordType ? faEyeSlash : faEye}
                  onClick={togglePassword}
                  onBlur={handleBlur}
                  invalid={Boolean(errors.password) && touched.password}
                  placeholder='userapp_password_placeholder'
                  className='input-login mb-1'
                />
                {touched.password && <FormFeedback>{errors.password}</FormFeedback>}
              </FormGroup>

              <div className='text-center my-2'>
                <span className='fs-magic-link' onClick={onClick}>
                  {t('connect_with_magic_link')}
                </span>
              </div>

              <div className='text-center'>
                {loading ? (
                  <Spinner size='sm' />
                ) : (
                  <button
                    className='btn-form rounded-3 col-10'
                    style={{ backgroundColor: primaryColor || '#D73755' }}
                    type='submit'
                  >
                    {t('userapp_connection')}
                  </button>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </>
    </div>
  );
};
export default LoginFormComponent;
