import { Col, Nav, NavItem, NavLink, Row, Spinner, TabContent, TabPane } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { CardHorizontal, CardVertical } from '@/components/common/cards';
import { _translate, WeverCoreContext, _Environment } from '@/services/core';
import { _BorderWever as BorderWever, _TextWever as TextWever } from '@/components/core/theme';
import { useContext } from 'react';
//import Icon from '@/components/common/icon';
import { useApp } from '@/contexts/main';
import useTab from './hooks/use-tab.hook';
import useDiagnostics from './hooks/use-diagnostics.hook';
import { DashboardInterface } from '@/entities/dashboard-interface';
import defaultImage from '@/assets/img/icons/common/edit.svg';
import Skeleton from '@/components/common/skeleton';


const Diagnostics = () => {
  const { appValues: state } = useApp();
  const { activeTab, onChangeTabs } = useTab();
  const { theme }: any = useContext(WeverCoreContext);
  const { loading } = useDiagnostics();
  const { locale }: DashboardInterface = useContext(WeverCoreContext);
  const navigate = useNavigate();
  const tabs = [
    {
      value: 1,
      text: _translate('userapp_my_diag_in_standby'),
      isComplete: false,
      diag: state.diagnosticsToComplete,
    },
    { value: 2, text: _translate('userapp_my_diag'), isComplete: true, diag: state.diagnostics },

  ];

  return (
    <div className='diag-container'>

      <div className='return-container py-4 mx-2'>
        {/* <Link className='text-dark  ' to='/dashboards' style={{ textDecoration: 'none' }}>
          <Icon icon='chevron-left' className=' mx-2' />
          <span className='return-tab'>{_translate('userapp_go_back')}</span>
        </Link> */}
      </div>

      <div className='diag-tabs-pc'>
        <Nav className='m-auto col-11 d-flex border-bottom mb-5'>
          {tabs.map((tab) => {
            return (
              <NavItem key={tab.value} className='col-6'>
                <BorderWever
                  theme='primary'
                  className={
                    (tab.value === activeTab ? '' : 'border-disabled ') +
                    'border-primary-wever border-tabs'
                  }
                >
                  <NavLink onClick={(e) => onChangeTabs(e, tab.value)}>
                    <p className='tabs-title text-center'>
                      <TextWever
                        theme='primary'
                        className={
                          (tab.value === activeTab ? '' : 'disabled ') + 'text-primary-wever'
                        }
                      >
                        {tab.text}
                      </TextWever>
                    </p>
                  </NavLink>
                </BorderWever>
              </NavItem>
            );
          })}
        </Nav>
        <TabContent activeTab={activeTab} className='col-11 m-auto'>
          {tabs.map((tab) => {
            return (
              <TabPane tabId={tab.value} lg='6'>
                <Row>
                  <Col sm='12'>
                    <div className='d-flex d-md-inline'>
                      {loading ? (
                        <>
                          <div className='w-100 d-flex justify-content-center align-items-center' style={{ height: "50vh !important" }}>
                            <Spinner color='primary' />
                          </div>
                          <div style={{ paddingTop: "10px", fontSize: "16px", color: "black", fontWeight: 700, textAlign: "center" }}>
                            {_translate('userapp_diag_loading')}
                          </div>
                        </>
                      ) : (
                        tab.diag &&
                        tab.diag.map((diagnostic, index: number) => {
                          return (
                            <CardHorizontal
                              titleCard={diagnostic.name[locale] || _translate(diagnostic.alias)}
                              titleLink={tab.isComplete ? _translate('userapp_modify_diag') : _translate('userapp_stand_by_diag_modify')}
                              text={diagnostic.description}
                              onClickFaPen={() => navigate(`/diagnostics/${diagnostic._id}`)}
                              imgCard={diagnostic && diagnostic.favicon && diagnostic.favicon.filePath ? `${_Environment.get('api_url')}/media/${diagnostic && diagnostic.favicon.filePath}` : defaultImage}
                              buttonHasBackground={!tab.isComplete}
                            />
                          );
                        })
                      )}
                    </div>
                  </Col>
                </Row>
              </TabPane>
            );
          })}
        </TabContent>
      </div>

      <div className='diag-tabs-mobile' style={{ marginBottom: '100px' }}>
        {tabs.map((tab) => {
          return (
            <>
              <h3 className='mx-4' style={{ color: theme?.secondaryColor }}>
                {tab.text}
              </h3>
              <div className='diagnostics-card-carousel'>
                {loading ? (
                  <div style={{ width: "100%" }}>
                    <div className='w-100 d-flex justify-content-center align-items-center' style={{ height: "50vh !important" }}>
                      <Spinner color='primary' />
                    </div>
                    <div style={{ paddingTop: "10px", fontSize: "16px", color: "black", fontWeight: 700, textAlign: "center" }}>
                      {_translate('userapp_diag_loading')}
                    </div>
                  </div>
                ) : (
                  tab.diag &&
                  tab.diag.map((diagnostic, index: number) => {
                    return (
                      <>
                        <CardVertical
                          titleCard={diagnostic.name[locale] || _translate(diagnostic.alias)}
                          titleLink={_translate('userapp_start_diag')}
                          text={diagnostic.description}
                          onClickFaPen={() => navigate(`/diagnostics/${diagnostic._id}`)}
                          imgCard={diagnostic && diagnostic.favicon && diagnostic.favicon.filePath ? `${_Environment.get('api_url')}/media/${diagnostic.favicon.filePath}` : defaultImage}
                          buttonHasBackground={!tab.isComplete}
                        />
                      </>
                    );
                  })
                )}
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};
export default Diagnostics;
