import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { _translate } from '@/services/core';
import Icon from '@/components/common/icon';
import { _Security, WeverCoreContext } from '@/services/core';
// @ts-ignore
import WeverCollector from 'wever-collector';
import 'wever-collector/src/assets/style.scss';
import Banner from '@/components/common/banner/banner';
import { DashboardInterface } from '@/entities/dashboard-interface';

const Collector = () => {
  const { locale }: DashboardInterface = useContext(WeverCoreContext);
  const { id } = useParams<{ id: string }>();

  return (
    <>
      <div className='mb-5 pb-5'>
        <Banner />
        <div className='return-container py-4 mx-2'>
          <Link className='text-dark  ' to='/diagnostics' style={{ textDecoration: 'none' }}>
            <Icon icon='chevron-left' className=' mx-2' />
            <span className='return-tab'>{_translate('userapp_go_back')}</span>
          </Link>
        </div>
      </div>
      <WeverCollector
        token={_Security.token}
        locale={locale}
        isMultilang={false}
        editionMode
        diagId={id}
      />
    </>
  );
};
export default Collector;
