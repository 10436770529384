import { faPen } from '@fortawesome/free-solid-svg-icons';
import Icon from './icon';

interface CardItemProps {
  titleCard: string;
  titleLink: string;
  text: string | null;
  onClickFaPen: (event: React.MouseEvent<HTMLElement>) => void;
  imgCard: string | null;
  buttonHasBackground: boolean;
}

export const CardHorizontal = ({
  imgCard,
  titleCard,
  text,
  onClickFaPen,
  titleLink,
  buttonHasBackground,
}: CardItemProps) => {
  return (
    <div className='mb-3 border border-white '>
      <div className='d-flex justify-content-between align-items-baseline'>
        <div className='card-horizotal-image'>
          <img
            src={imgCard ?? ''}
            className='img-fluid  h-100 img-card-horizontal'
            alt='...'
          />
        </div>
        <div className='mx-3 w-100'>
          <p className='card-title text-center text-sm-center text-md-start'>{titleCard}</p>
          <p className='card-text d-none d-sm-block d-sm-none d-md-block '>{text}</p>
        </div>
        <div
          className='col-md-3 d-flex justify-content-center mx-md-2 align-items-center'
          onClick={onClickFaPen}
        >
          <div>
            <p className={buttonHasBackground ? 'inverted-card-faPen' : 'card-faPen'}>
              <Icon icon={faPen} className='border-bottom border-dark border-1' /> {titleLink}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export const CardVertical = ({
  imgCard,
  titleCard,
  onClickFaPen,
  titleLink,
  buttonHasBackground,
}: CardItemProps) => {
  return (
    <div className='mb-3 mx-3'>
      <div className='d-flex flex-column align-content-center vertical-card-container h-100'>
        <div className='card-horizotal-image'>
          <img src={imgCard ?? ''} className='img-fluid h-100 img-card-horizontal' alt='...' />
        </div>
        <div className='mx-3'>
          <p className='card-title text-center'>{titleCard}</p>
        </div>
        <div
          className='d-flex justify-content-center align-items-center mt-3'
          onClick={onClickFaPen}
        >
          <p className={buttonHasBackground ? 'inverted-card-faPen' : 'card-faPen'}>
            <Icon icon={faPen} className='border-bottom border-dark border-1' /> {titleLink}
          </p>
        </div>
      </div>
    </div>
  );
};
